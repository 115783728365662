<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card dark outlined>
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-home </v-icon>
            Products
            <v-spacer />
          </v-card-title>
          <v-card-text class="pa-0">
            <v-data-table
              :headers="headers"
              :items="allProducts"
              single-select
              class="elevation-5"
              :item-class="itemRowBackground"
            >
              <template v-slot:item.TagIds="{ item }">
                {{
                  item.TagIds
                    ? haddleCheckTags(item.TagIds).join(", ")
                    : "No data available"
                }}
              </template>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row>
                    <v-row class="mt-2">
                      <v-col cols="3">
                        <v-autocomplete
                          :items="allStallHolders"
                          v-model="selectedStallHolder"
                          item-text="StallholderName"
                          return-object
                          filled
                          dense
                          outlined
                          hide-details
                          label="StallHolders"
                          @change="haddleChangeSH"
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-title>
                                <v-icon small color="red lighten-1">
                                  mdi-information
                                </v-icon>
                                No event found
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="3">
                        <v-combobox
                          filled
                          dense
                          outlined
                          v-model="editedItem.CategoryId"
                          :items="CategoryList"
                          label="Categories"
                          item-text="CategoryName"
                        >
                        </v-combobox>
                      </v-col>
                    </v-row>
                  </v-row>

                  <v-dialog v-model="dialog" max-width="800px" dark>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!selectedStallHolder.StallholderId"
                      >
                        <v-icon left x-small> mdi-plus </v-icon>
                        Add Products
                      </v-btn>

                      <v-btn
                        :disabled="!selectedStallHolder.StallholderId"
                        @click="haddleDicountSurcharge"
                        class="mr-3 mb-2"
                        small
                        dark
                      >
                        Dicount Surcharge
                      </v-btn>

                      <v-btn
                        :disabled="!selectedStallHolder.StallholderId"
                        @click="haddleCreateIsCashOut"
                        class="mr-3 mb-2"
                        small
                        dark
                        v-bind:class="{ gray: !IsCashOut, green: IsCashOut }"
                        v-on:click="IsCashOut = !IsCashOut"
                      >
                        <v-checkbox
                          v-model="IsCashOut"
                          hide-details
                          v-on:click="IsCashOut = !IsCashOut"
                        ></v-checkbox>

                        Enable Cash Out
                      </v-btn>

                      <v-btn
                        :disabled="!selectedStallHolder.StallholderId"
                        @click="haddleCreateManualProduct"
                        class="mr-3 mb-2"
                        small
                        dark
                        v-bind:class="{
                          gray: !ManualProduct,
                          green: ManualProduct,
                        }"
                        v-on:click="ManualProduct = !ManualProduct"
                      >
                        <v-checkbox
                          v-model="ManualProduct"
                          hide-details
                          v-on:click="ManualProduct = !ManualProduct"
                        ></v-checkbox>

                        Enable Manual Product
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h6">
                        {{ formTitle }}
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-card-text>
                        <v-container>
                          <v-form ref="form" v-model="valid" lazy-validation>
                            <v-row>
                              <v-col cols="6">
                                <label
                                  ><B>Event :-</B>
                                  {{
                                    selectedEvent.EventName
                                      ? selectedEvent.EventName
                                      : "N/A"
                                  }}</label
                                >
                              </v-col>
                              <v-col cols="6">
                                <label
                                  ><B>Stallholder :-</B>
                                  {{
                                    selectedStallHolder.StallholderName
                                      ? selectedStallHolder.StallholderName
                                      : "N/A"
                                  }}</label
                                >
                              </v-col>
                              <!-- <v-col cols="4">
                                    <label><B>Stall :-</B> {{ selectedStall.StallName ? selectedStall.StallName : 'N/A'}}</label>
                                </v-col> -->
                            </v-row>

                            <v-row>
                              <v-col cols="4">
                                <v-combobox
                                  v-model="editedItem.CategoryId"
                                  :items="CategoryList"
                                  label="Categories"
                                  item-text="CategoryName"
                                >
                                </v-combobox>
                              </v-col>
                              <v-col cols="4">
                                <v-combobox
                                  v-model="editedItem.TagIds"
                                  multiple
                                  :items="TagList"
                                  label="Tag"
                                  item-text="TagName"
                                >
                                </v-combobox>
                              </v-col>
                              <v-col cols="4">
                                <v-combobox
                                  v-model="editedItem.ProductOptionIds"
                                  multiple
                                  :items="ProductOptionList"
                                  label="Product Options"
                                  item-text="ProductOptionName"
                                >
                                </v-combobox>
                              </v-col>
                            </v-row>

                            <v-text-field
                              v-model="editedItem.ProductName"
                              label="Product Name"
                              :rules="[
                                (v) => !!v || 'Product Name is required',
                              ]"
                            ></v-text-field>

                            <v-text-field
                              v-model="editedItem.ShortName"
                              label="Short Name"
                            ></v-text-field>

                            <v-text-field
                              v-model="editedItem.Price"
                              type="number"
                              label="Price"
                              :rules="[(v) => !!v || 'Price is required']"
                            ></v-text-field>

                            <v-text-field
                              v-model="editedItem.StandardDrinks"
                              type="number"
                              label="Standard Drinks"
                              :rules="[
                                (v) => !!v || 'Standard Drinks is required',
                              ]"
                            ></v-text-field>

                            <v-btn
                              class="mr-3"
                              small
                              dark
                              v-bind:class="{
                                gray: !editedItem.GstFlag,
                                green: editedItem.GstFlag,
                              }"
                              v-on:click="
                                editedItem.GstFlag = !editedItem.GstFlag
                              "
                            >
                              <v-checkbox
                                class="mb-5"
                                v-model="editedItem.GstFlag"
                                hide-details
                                v-on:click="
                                  editedItem.GstFlag = !editedItem.GstFlag
                                "
                              ></v-checkbox>
                              GST Applicable
                            </v-btn>

                            <v-divider class="mt-5 mb-5"></v-divider>

                            <!-- Start of stall products -->
                            <!-- <span class="mt-4 mb-4">Stall Products</span>
                              <v-row v-for="(item, index) in stallProducts" :key="index">
                                <v-col cols="3" class="mt-3">
                                  <v-autocomplete
                                      :items="stalls"
                                      v-model="item.StallIds"
                                      item-text="StallName"
                                      return-object
                                      filled
                                      dense
                                      outlined
                                      hide-details
                                      label="Stalls"
                                  >
                                      <template v-slot:no-data>
                                      <v-list-item>
                                          <v-list-item-title>
                                          <v-icon small color="red lighten-1">
                                              mdi-information
                                          </v-icon>
                                          No event found
                                          </v-list-item-title>
                                      </v-list-item>
                                      </template>
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="2">
                                  <v-text-field
                                    v-model="item.ProductName"
                                    label="Product Name"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-text-field
                                    v-model="item.ShortName"
                                    label="Short Name"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-text-field
                                    v-model="item.Price"
                                    type="number"
                                    label="Price"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="3" class="mt-5">
                                  <v-btn
                                    color="red lighten-2"
                                    x-small
                                    v-show="index || (!index && stallProducts.length > 1)"
                                    @click="removeRow(index)"
                                  >
                                  Remove
                                  </v-btn> &nbsp;
                                  <v-btn
                                    color="lighten-2"
                                    x-small
                                    @click="addRow()"
                                  >
                                  Add
                                  </v-btn>
                                </v-col>
                              </v-row> -->

                            <!-- End of stall products -->

                            <!-- <v-row>
                                    <v-col cols="6">
                                        <label>Text Color</label>
                                        <v-color-picker
                                            dot-size="25"
                                            mode="hexa"
                                            hide-mode-switch
                                            swatches-max-height="150"
                                            v-model="editedItem.TextColor"
                                        ></v-color-picker>
                                    </v-col>
                                    <v-col cols="6">
                                        <label>Background Color</label>
                                        <v-color-picker
                                            dot-size="25"
                                            mode="hexa"
                                            hide-mode-switch
                                            swatches-max-height="150"
                                            v-model="editedItem.BackgroundColor"
                                        ></v-color-picker>
                                    </v-col>
                               </v-row> -->
                            <!-- </v-col> -->
                          </v-form>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                          Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="save">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog dark v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5"
                        >Are you sure you want to delete Product ?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItemConfirm"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog
                    v-model="dialogDicountSurcharge"
                    max-width="500px"
                    dark
                  >
                    <v-card>
                      <v-card-title class="text-h6">
                        Dicount Surcharge (%)
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <label
                                ><B>Event :-</B>
                                {{
                                  selectedEvent.EventName
                                    ? selectedEvent.EventName
                                    : "N/A"
                                }}</label
                              >
                            </v-col>
                            <v-col cols="12">
                              <label
                                ><B>Stallholder :-</B>
                                {{
                                  selectedStallHolder.StallholderName
                                    ? selectedStallHolder.StallholderName
                                    : "N/A"
                                }}</label
                              >
                            </v-col>
                            <v-col cols="12">
                              <label><B>Category :-</B>TBC</label>
                            </v-col>
                            <v-col cols="12">
                              <label
                                ><B>Product Name :-</B>Discount Surcharge
                                Item</label
                              >
                            </v-col>
                            <v-col cols="12">
                              <label
                                ><B>Short Name :-</B>Discount Surcharge
                                Item</label
                              >
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="DiscountSurchargePercentage"
                                type="number"
                                label="Discount Surcharge(%)"
                                :rules="[
                                  (v) =>
                                    !!v || 'Discount Surcharge (%) is required',
                                ]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-btn
                                class="mr-3"
                                small
                                dark
                                v-bind:class="{
                                  gray: !DiscountSurchargeManager,
                                  green: DiscountSurchargeManager,
                                }"
                                v-on:click="
                                  DiscountSurchargeManager =
                                    !DiscountSurchargeManager
                                "
                              >
                                <v-checkbox
                                  class="mb-5"
                                  v-model="DiscountSurchargeManager"
                                  hide-details
                                  v-on:click="
                                    DiscountSurchargeManager =
                                      !DiscountSurchargeManager
                                  "
                                ></v-checkbox>
                                Discount Surcharge Manager
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-btn
                          color="red darken-1"
                          text
                          @click="deleteDicountSurcharge"
                        >
                          Delete
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="closeDicountSurcharge"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="saveDicountSurcharge"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  v-if="
                    (item.ManualProduct === undefined ||
                      item.ManualProduct === false) &&
                    (item.IsCashOut === undefined ||
                      item.IsCashOut === false) &&
                    (item.DiscountSurchargePercentage === 0 ||
                      item.DiscountSurchargePercentage === undefined)
                  "
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  v-if="
                    (item.ManualProduct === undefined ||
                      item.ManualProduct === false) &&
                    (item.IsCashOut === undefined ||
                      item.IsCashOut === false) &&
                    (item.DiscountSurchargePercentage === 0 ||
                      item.DiscountSurchargePercentage === undefined)
                  "
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn color="primary"> No product for this event </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import "izitoast/dist/css/iziToast.min.css"; // loading css
import iziToast from "izitoast/dist/js/iziToast.min.js"; // you have access to iziToast now
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogDicountSurcharge: false,

    selectedStallHolder: "",
    selectedStall: "",
    stalls: [],
    headers: [
      { text: "Product Name", value: "ProductName" },
      { text: "Short Name", value: "ShortName" },
      { text: "Tag(s)", value: "TagIds" },
      { text: "Discount Surcharge (%)", value: "DiscountSurchargePercentage" },
      { text: "Price ($)", value: "Price" },
      { text: "Standard Drinks", value: "StandardDrinks" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    allProducts: [],
    selectedAllProducts: [],

    stallHolders: [],
    stallHoldersList: [],

    editedIndex: -1,
    editedItem: {
      EventId: "",
      StallholderId: "",
      CategoryId: "",
      TagIds: [],
      ProductOptionIds: "",
      ProductName: "",
      ShortName: "",
      Price: "",
      DiscountSurchargePercentage: 0,
      StandardDrinks: "",
      GstFlag: true,
      DiscountSurchargeManager: false,
      MandatoryOptions: false,
      ManualProduct: false,
      IsCashOut: false,
    },
    defaultItem: {
      EventId: "",
      StallholderId: "",
      CategoryId: "",
      TagIds: [],
      ProductOptionIds: "",
      ProductName: "",
      ShortName: "",
      Price: "",
      DiscountSurchargePercentage: 0,
      StandardDrinks: "",
      GstFlag: true,
      DiscountSurchargeManager: false,
      MandatoryOptions: false,
      ManualProduct: false,
      IsCashOut: false,
    },

    ManualProductObj: {},
    ManualProduct: false,
    IsCashOutProductObj: {},
    IsCashOut: false,
    DiscountSurchargePercentage: 0,
    DiscountSurchargeManager: false,
    DiscountSurchargePercentageProductObj: {},

    CategoryList: [],
    TagList: [],
    ProductOptionList: [],

    // stallProducts: [{ StallIds: [], ProductName: '', ShortName: '', Price: 0}],

    valid: true,
  }),

  computed: {
    ...mapState("filter", [
      "globalEventFilter",
      "allEvents",
      "allStallHolders",
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Product" : "Edit Product";
    },
    selectedEvent: {
      get() {
        return this.globalEventFilter;
      },
      set(value) {
        console.log("EVENT IS CHANGED MAKE API REQUEST", value);
      },
    },
    events() {
      return this.allEvents.map((obj) => {
        return { text: obj.EventName, value: obj.EventId };
      });
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogDicountSurcharge(val) {
      val || this.closeDicountSurcharge();
    },
  },

  created() {
    this.halldeStallHolder();
    this.getAllProducts();
  },
  methods: {
    ...mapMutations("filter", [
      "setGlobalEventFilter",
      "setAllEvents",
      "setFilterStallHolders",
    ]),
    ...mapActions("admin", ["getProducts", "createCategories"]),
    getAllProducts() {
      let eventId = localStorage.getItem("setSelectedEventId");
      let StallholderId = localStorage.getItem("setSelectedStallholderId");

      let paramObj = {};
      paramObj.EventId = eventId;
      paramObj.StallholderId = StallholderId;

      console.log("paramObj");
      console.log(paramObj);

      this.getProducts(paramObj).then((response) => {
        this.selectedAllProducts = response.Products;
        this.allProducts = response.Products;

        this.CategoryList = response.ProductCategories;
        this.TagList = response.StallholderTags;
        this.ProductOptionList = response.ProductOptions;

        console.log("response.Products");
        console.log(response.Products);
        // Manual Product
        let isSelectManualProduct = response.Products.filter(function (o1) {
          return o1.ManualProduct && o1.ManualProduct === true;
        });
        this.ManualProductObj = isSelectManualProduct[0];
        this.ManualProduct = false;
        this.ManualProduct = isSelectManualProduct[0].ManualProduct;

        // CashOut Product
        let isSelectCashOutProduct = response.Products.filter(function (o1) {
          return o1.IsCashOut && o1.IsCashOut === true;
        });
        this.IsCashOutProductObj = isSelectCashOutProduct[0];
        this.IsCashOut = false;
        this.IsCashOut = isSelectCashOutProduct[0].IsCashOut;

        // Dicount Surcharge
        let discountSurchargeProduct = response.Products.filter(function (o1) {
          return o1.ProductName && o1.ProductName === "Discount Surcharge Item";
        });
        this.DiscountSurchargePercentageProductObj =
          discountSurchargeProduct[0];
        this.DiscountSurchargePercentage =
          response.Products.DiscountSurchargePercentage;
      });
    },

    itemRowBackground: function (item) {
      return item.ManualProduct === true
        ? "indigo"
        : item.IsCashOut === true
        ? "indigo"
        : "";
    },

    haddleCheckTags: function (tagIds) {
      var result = this.TagList.filter(function (o1) {
        return tagIds.some(function (o2) {
          return o1.TagId === o2;
        });
      }).map(function (o) {
        return o.TagName;
      });
      return result;
    },

    haddleCheckProductOptionIds: function (OptionIds) {
      var result = this.ProductOptionList.filter(function (o1) {
        return OptionIds.some(function (o2) {
          return o1.ProductOptionId === o2;
        });
      }).map(function (o) {
        return o.ProductOptionName;
      });
      return result;
    },

    haddleChangeSH: function (obj) {
      obj.Stalls;
      this.stalls = obj.Stalls;

      this.allProducts = this.selectedAllProducts.filter((element) => {
        return element.StallholderId === obj.StallholderId;
      });

      localStorage.setItem("setSelectedEventId", this.selectedEvent.EventId);
      localStorage.setItem("setSelectedStallholderId", obj.StallholderId);
      this.getAllProducts();
    },
    halldeStallHolder: function () {
      if (this.selectedEvent.EventName !== undefined) {
        this.haddleChangeEvent(this.selectedEvent);
      }
    },

    haddleChangeEvent: function (obj) {
      this.stallHolders = [];
      this.editedItem.StallholderId = "";
      let selectedObj = this.allEvents.filter((element) => {
        return element.EventId === obj.EventId;
      });
      this.stallHoldersList = selectedObj[0].Stallholders;
      this.stallHolders = selectedObj[0].Stallholders.map((obj) => {
        return { text: obj.StallholderName, value: obj.StallholderId };
      });
    },

    editItem(item) {
      this.editedIndex = this.allProducts.indexOf(item);
      //  this.editedItem.EventId = this.allEvents((item) => item.);
      console.log("item");
      console.log(item);

      this.editedItem.EventId = item.EventId;
      this.editedItem.StallholderId = item.StallholderId;

      this.editedItem.CategoryId = this.CategoryList.filter((element) => {
        return element.CategoryId === item.CategoryId;
      });
      this.editedItem.CategoryId = this.editedItem.CategoryId[0];

      this.editedItem.TagIds = this.haddleCheckTags(item.TagIds);
      this.editedItem.ProductOptionIds = this.haddleCheckProductOptionIds(
        item.ProductOptionIds
      );

      this.editedItem.ProductId = item.ProductId;
      this.editedItem.ProductName = item.ProductName;
      this.editedItem.ShortName = item.ShortName;
      this.editedItem.Price = item.Price;
      this.editedItem.DiscountSurchargeManager = item.DiscountSurchargeManager;
      this.editedItem.MandatoryOptions = item.MandatoryOptions;
      this.editedItem.StandardDrinks = item.StandardDrinks;

      // stall Product section
      // this.editedItem.stallProducts = [];

      let selected = this.allStallHolders.filter((element) => {
        return element.StallholderId === item.StallholderId;
      });

      this.selectedStallHolder = selected[0];

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.allStallHolders.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.allStallHolders.splice(this.editedIndex, 1);

      try {
        if (this.editedItem.StallholderId) {
          let event_item = [
            {
              Type: "product",
              Action: "delete",
              Properties: this.editedItem,
            },
          ];

          let submitEvent = {
            event_items: event_item,
          };
          this.createCategories(submitEvent)
            .then((response) => {
              console.log("response");
              console.log(response);

              iziToast.success({
                title: "Product",
                message: "Successfully delete record!",
                position: "topRight",
              });

              this.getAllProducts();
            })
            .catch((err) => {
              console.log("err");
              console.log(err);

              iziToast.warning({
                title: "Stallholder",
                message: "Fail to delete record!",
                position: "topRight",
              });

              this.getAllProducts();
            });
        }
      } catch (e) {
        console.log(e);
      }

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDicountSurcharge() {
      this.dialogDicountSurcharge = false;
    },

    // addRow() {
    //   let x = this.stallProducts.length - 1;

    //   if (this.stallProducts[x].StallIds && this.stallProducts[x].ProductName && this.stallProducts[x].ShortName && this.stallProducts[x].Price) {
    //     this.stallProducts.push({});
    //   }

    //   for (var j = 0; j < this.stallProducts.length - 1; j++) {
    //     if (this.stallProducts[j].StallIds.StallId !== undefined) {
    //       this.stalls = this.stalls.map(obj=>{
    //         if (obj.StallId === this.stallProducts[j].StallIds.StallId) {
    //           obj.disabled = true;
    //         }
    //         return obj;
    //       });

    //     }
    //   }

    // },

    // removeRow(index) {
    //   for (var j = 0; j < this.stallProducts.length; j++) {
    //     if (this.stallProducts[j].StallIds && this.stallProducts[j].StallIds !== undefined &&  this.stallProducts[j].StallIds.StallId !== undefined) {
    //       this.stalls = this.stalls.map(obj=>{
    //         if (obj.StallId === this.stallProducts[j].StallIds.StallId) {
    //           obj.disabled = false;
    //         }
    //         return obj;
    //       });

    //     }
    //   }

    //   this.stallProducts.splice(index, 1);
    // },

    save() {
      console.log("Starting save()", this.editedItem);
      if (this.$refs.form.validate()) {
        try {
          if (this.$refs.form.validate()) {
            this.editedItem.EventId = this.selectedEvent.EventId;
            this.editedItem.StallholderId =
              this.selectedStallHolder.StallholderId;
            this.editedItem.CategoryId = this.editedItem.CategoryId.CategoryId;
            this.editedItem.TagIds = this.editedItem.TagIds.map((obj) => {
              return obj.TagId;
            });
            console.log(
              "save () this.editedItem.ProductOptionIds: ",
              this.editedItem.ProductOptionIds
            );
            if (this.editedItem.ProductOptionIds !== "") {
              this.editedItem.ProductOptionIds =
                this.editedItem.ProductOptionIds.map((obj) => {
                  return obj.ProductOptionId;
                });
            } else {
              delete this.editedItem.ProductOptionIds;
            }

            // if (this.stallProducts.length !== 0) {
            //   this.stallProducts = this.stallProducts.map(obj=>{
            //     obj.StallIds = obj.StallIds.StallId;
            //     return obj;
            //   });

            //   this.editedItem.StallProducts = this.stallProducts;
            // }
            console.log("editedItem");
            console.log(this.editedItem);
            let event_item = [
              {
                Type: "product",
                Action: this.editedIndex === -1 ? "Create" : "Edit",
                Properties: this.editedItem,
              },
            ];

            let submitEvent = {
              event_items: event_item,
            };
            console.log("submitEvent");
            console.log(submitEvent);
            this.createCategories(submitEvent)
              .then((response) => {
                console.log("response");
                console.log(response);

                iziToast.success({
                  title: "Product",
                  message: "Successfully inserted record!",
                  position: "topRight",
                });

                this.getAllProducts();
              })
              .catch((err) => {
                console.log("err");
                console.log(err);

                iziToast.warning({
                  title: "Product",
                  message: "Fail to inserted record!",
                  position: "topRight",
                });
              });
          }
        } catch (e) {
          console.log(e);
        }
        this.close();
      }
    },
    // Manual Product
    haddleCreateManualProduct: function () {
      if (!this.ManualProduct) {
        try {
          let submitEvent = {};
          submitEvent.ManualProduct = true;
          submitEvent.EventId = this.selectedEvent.EventId;
          submitEvent.StallholderId = this.selectedStallHolder.StallholderId;
          submitEvent.CategoryId = "TBC";
          submitEvent.ProductName = "Manual Product";
          submitEvent.ShortName = "Manual Product";
          submitEvent.Deleted = false;

          let isAction = "Create";
          this.haddleSubmit(submitEvent, isAction);
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          let submitEvent = {};
          submitEvent.EventId = this.selectedEvent.EventId;
          submitEvent.StallholderId = this.selectedStallHolder.StallholderId;
          submitEvent.ProductId = this.ManualProductObj.ProductId;

          let isAction = "Delete";
          this.haddleSubmit(submitEvent, isAction);
        } catch (e) {
          console.log(e);
        }
      }
    },
    // IsCashOut
    haddleCreateIsCashOut: function () {
      if (!this.IsCashOut) {
        try {
          let submitEvent = {};
          submitEvent.IsCashOut = true;
          submitEvent.EventId = this.selectedEvent.EventId;
          submitEvent.StallholderId = this.selectedStallHolder.StallholderId;
          submitEvent.CategoryId = "TBC";
          submitEvent.ProductName = "Cashout";
          submitEvent.ShortName = "Cashout";
          submitEvent.Deleted = false;

          let isAction = "Create";
          this.haddleSubmit(submitEvent, isAction);
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          let submitEvent = {};
          submitEvent.EventId = this.selectedEvent.EventId;
          submitEvent.StallholderId = this.selectedStallHolder.StallholderId;
          submitEvent.ProductId = this.IsCashOutProductObj.ProductId;

          let isAction = "Delete";
          this.haddleSubmit(submitEvent, isAction);
        } catch (e) {
          console.log(e);
        }
      }
    },
    // Dicount Surcharge
    haddleDicountSurcharge: function () {
      this.dialogDicountSurcharge = true;
      this.DiscountSurchargePercentage = 0;
      this.DiscountSurchargeManager = false;
      if (
        this.DiscountSurchargePercentageProductObj !== undefined &&
        this.DiscountSurchargePercentageProductObj.ProductId !== undefined
      ) {
        this.DiscountSurchargePercentage =
          this.DiscountSurchargePercentageProductObj.DiscountSurchargePercentage;
        this.DiscountSurchargeManager =
          this.DiscountSurchargePercentageProductObj.DiscountSurchargeManager;
      }
    },
    saveDicountSurcharge: function () {
      try {
        let submitEvent = {};
        submitEvent.DiscountSurchargeItem = true;
        submitEvent.EventId = this.selectedEvent.EventId;
        submitEvent.StallholderId = this.selectedStallHolder.StallholderId;
        submitEvent.DiscountSurchargePercentage =
          this.DiscountSurchargePercentage;
        submitEvent.DiscountSurchargeManager = this.DiscountSurchargeManager;
        submitEvent.CategoryId = "TBC";
        submitEvent.ProductName = "Discount Surcharge Item";
        submitEvent.ShortName = "Discount Surcharge Item";

        let isAction = "Create";
        if (
          this.DiscountSurchargePercentageProductObj !== undefined &&
          this.DiscountSurchargePercentageProductObj.ProductId !== undefined
        ) {
          submitEvent.ProductId =
            this.DiscountSurchargePercentageProductObj.ProductId;
          isAction = "Edit";
        }

        this.haddleSubmit(submitEvent, isAction);
        this.dialogDicountSurcharge = false;
      } catch (e) {
        console.log(e);
      }
    },
    deleteDicountSurcharge: function () {
      try {
        let submitEvent = {};
        submitEvent.EventId = this.selectedEvent.EventId;
        submitEvent.StallholderId = this.selectedStallHolder.StallholderId;
        let isAction;
        if (
          this.DiscountSurchargePercentageProductObj !== undefined &&
          this.DiscountSurchargePercentageProductObj.ProductId !== undefined
        ) {
          submitEvent.ProductId =
            this.DiscountSurchargePercentageProductObj.ProductId;
          isAction = "Delete";
        }

        this.haddleSubmit(submitEvent, isAction);
        this.dialogDicountSurcharge = false;
      } catch (e) {
        console.log(e);
      }
    },
    haddleSubmit: function (obj, action) {
      try {
        let event_item = [
          {
            Type: "product",
            Action: action,
            Properties: obj,
          },
        ];

        let submit = {
          event_items: event_item,
        };
        this.createCategories(submit)
          .then((response) => {
            console.log("response");
            console.log(response);

            iziToast.success({
              title: "Product",
              message: "Successfully " + action + " record!",
              position: "topRight",
            });

            this.getAllProducts();
          })
          .catch((err) => {
            console.log("err");
            console.log(err);

            iziToast.warning({
              title: "Product",
              message: "Fail to inserted record!",
              position: "topRight",
            });
          });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style scoped>
h1 {
  color: #a09c94;
  font-family: sans-serif;
}
</style>
